<template>
  <a-modal :confirm-loading="loading" title="编辑用户" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="姓名" prop="nickname">
        <a-input v-model="form.nickname" :max-length="128" placeholder="请输入姓名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电话号码" prop="phone_number">
        <a-input v-model="form.phone_number" :max-length="11" placeholder="请输入电话号码"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址" prop="email_address">
        <a-input v-model="form.email_address" :max-length="128" placeholder="请输入邮箱地址"></a-input>
      </a-form-model-item>
      <a-form-model-item label="权限">
        <a-row :gutter="16">
          <a-col span="4">
            <a-form-model-item prop="admin">
              <a-switch v-model="form.admin" checked-children="管理员" un-checked-children="用户"></a-switch>
            </a-form-model-item>
          </a-col>
          <a-col v-if="!form.admin" span="20">
            <a-form-model-item prop="role" :rules="[{ message: '请选择角色', required: true, trigger: 'change' }]">
              <user-role-select v-model="form.role" :allow-clear="false" :label-in-value="true"></user-role-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getUser, updateUser } from '@/api/user'
import { validatePhoneNumber } from '@/utils/validates'
import UserRoleSelect from './UserRoleSelect'

export default {
  name: 'UpdateForm',
  components: { UserRoleSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: { admin: false },
      rules: {
        nickname: [{ message: '请输入姓名', required: true, trigger: 'blur' }],
        phone_number: [{ validator: validatePhoneNumber, trigger: 'blur' }],
        email_address: [
          { message: '请输入邮箱地址', required: true, trigger: 'blur' },
          { message: '请输入正确的邮箱地址', type: 'email', trigger: 'blur' }
        ]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getUser(id).then(res => {
        const data = res.data
        this.form.nickname = data.nickname
        this.form.phone_number = data.phone_number
        this.form.email_address = data.email_address
        this.form.admin = data.admin
        if (!this.form.admin && data.role) this.form.role = { key: data.role.id, label: data.role.name }
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = { admin: false }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateUser(this.id, {
            nickname: this.form.nickname,
            phone_number: this.form.phone_number,
            email_address: this.form.email_address,
            admin: this.form.admin,
            role_id: !this.form.admin && this.form.role ? this.form.role.key : undefined
          }).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
