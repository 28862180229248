<template>
  <a-modal :confirm-loading="loading" title="创建用户" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="用户名" prop="username">
        <a-input v-model="form.username" :max-length="128" placeholder="请输入用户名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="nickname">
        <a-input v-model="form.nickname" :max-length="128" placeholder="请输入姓名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电话号码" prop="phone_number">
        <a-input v-model="form.phone_number" :max-length="11" placeholder="请输入电话号码"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址" prop="email_address">
        <a-input v-model="form.email_address" :max-length="128" placeholder="请输入邮箱地址"></a-input>
      </a-form-model-item>
      <a-form-model-item label="密码策略" prop="password_strategy">
        <a-radio-group v-model="form.password_strategy" :options="options"></a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.password_strategy === 'manual'"
        label="密码"
        prop="password"
        :rules="[
          { message: '请输入密码', required: true, trigger: 'blur' },
          { message: '请输入至少 6 位密码', min: 6, trigger: 'blur' }
        ]"
      >
        <a-input-password v-model="form.password" autocomplete="new-password" :max-length="128" placeholder="请输入密码"></a-input-password>
      </a-form-model-item>
      <a-form-model-item label="角色">
        <a-row :gutter="16">
          <a-col span="4">
            <a-form-model-item prop="admin">
              <a-switch v-model="form.admin" checked-children="管理员" un-checked-children="用户"></a-switch>
            </a-form-model-item>
          </a-col>
          <a-col v-if="!form.admin" span="20">
            <a-form-model-item prop="role_id" :rules="[{ message: '请选择角色', required: true, trigger: 'change' }]">
              <user-role-select v-model="form.role_id" :allow-clear="false"></user-role-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createUser } from '@/api/user'
import { validatePhoneNumber } from '@/utils/validates'
import UserRoleSelect from './UserRoleSelect'

export default {
  name: 'CreateForm',
  components: { UserRoleSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        password_strategy: 'auto',
        admin: false
      },
      rules: {
        username: [{ message: '请输入用户名', required: true, trigger: 'blur' }],
        nickname: [{ message: '请输入姓名', required: true, trigger: 'blur' }],
        phone_number: [{ validator: validatePhoneNumber, trigger: 'blur' }],
        email_address: [
          { message: '请输入邮箱地址', required: true, trigger: 'blur' },
          { message: '请输入正确的邮箱地址', type: 'email', trigger: 'blur' }
        ]
      },
      options: [
        { label: '自动生成，邮件发送', value: 'auto' },
        { label: '手动设置', value: 'manual' }
      ]
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createUser(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  },
  watch: {
    'form.admin' (v) {
      if (v) delete this.form.role_id
    }
  }
}
</script>
